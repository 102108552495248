import React, { useState } from 'react';
import clsx from 'clsx';

// components
import Section from '../Section';
import Button from '../Button';
import Image from '../Image';

// prop types
interface Items {
    title?: string
    intro?: string
    imgSrc?: string
    imgSrcMedium?: string
    imgSrcSmall?: string
    imgAlt?: string
    imgWidth?: number
    imgCaption?: string
    imgSizes?: string
    link?: string
}

interface DynamicStoriesProps {
    sectionHeader?: React.ReactNode
    items?: Items[]
    allStoriesLink?: string
    prefix?: string
    className?: string
}

export default function DynamicStories({
    sectionHeader,
    items,
    allStoriesLink,
    prefix,
    className
}: DynamicStoriesProps) {
    const classes = clsx(
        'dynamic-stories',
        className
    )

    const itemId = `DSItem-${prefix}`
    const [selectedIndex, setSelectedIndex] = useState(0)

    const handleSelectItem = (index: number) => {
        setSelectedIndex(index);

        // scroll to the selected id + 20px if screen width is less than 992px
        if (window.innerWidth < 992) {
            const element = document.getElementById(itemId);
            if (element) {
                const offset = 20;
                const elementPosition = element.getBoundingClientRect().top + window.scrollY;
                window.scrollTo({
                    top: elementPosition - offset,
                    behavior: 'smooth'
                });
            }
        }
    }

    return (
        <Section className={classes}>
            {sectionHeader}
            <div className="grid-container">
                <div className="grid-row dynamic-stories__wrapper">
                    <div className="c-lg-4 dynamic-stories__content">
                        {/* loop items here inside an ul */}
                        <ul className="dynamic-stories__list">
                            {items && items.map((item, i) => (
                                <li key={i} className={`dynamic-stories__item ${i === selectedIndex ? 'dynamic-stories__item--selected' : ''}`}>
                                    <h3 className="dynamic-stories__title">{item.title}</h3>
                                    <p className="dynamic-stories__intro">{item.intro}</p>
                                    <button
                                        className="dynamic-stories__selector absolute-link"
                                        aria-label='Select this story'
                                        onClick={() => handleSelectItem(i)} // select item
                                        aria-controls={'#' + itemId}
                                        aria-selected={i === selectedIndex}
                                    ></button>
                                </li>
                            ))}
                        </ul>
                        {allStoriesLink && <Button text='All Customer Stories' href={allStoriesLink} className='dynamic-stories__see-all' variant='default' size='md' />}
                    </div>
                    {items[selectedIndex] && (
                        <a href={items[selectedIndex].link} id={itemId} aria-label={`Read more about ` + items[selectedIndex].title} className='c-lg-8 dynamic-stories__figure-wrapper'>
                            <figure className="c-lg-8 dynamic-stories__figure">
                                {items[selectedIndex].imgSrc && (
                                    <Image
                                        src={items[selectedIndex].imgSrc}
                                        srcMedium={items[selectedIndex].imgSrcMedium}
                                        srcSmall={items[selectedIndex].imgSrcSmall}
                                        width={items[selectedIndex].imgWidth}
                                        sizes={items[selectedIndex].imgSizes}
                                        alt={items[selectedIndex].imgAlt}
                                    />
                                )}
                                {items[selectedIndex].imgCaption && <figcaption className="dynamic-stories__figcaption">{items[selectedIndex].imgCaption}</figcaption>}
                            </figure>
                        </a>
                    )}
                </div>
            </div>
        </Section>
    );
}
