import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';

interface KeyDataCard {
    title?: string;
    subtitle?: string;
    text?: string;
}

interface MacroKeyDataProps {
    className?: string;
    cards?: KeyDataCard[];
    color?: 'muted'| 'white'| 'primary' | 'secondary' | 'orangeTwine' | 'brownSorell' | 'greenSmoke' | 'redRust' | 'lavender'
}

export default function MacroKeyData({
    className,
    cards,
    color = 'muted',
}: MacroKeyDataProps) {
    const classes = clsx(
        'key-data',
        `key-data--${color}`,
        className
    );

    return (
        <div className={classes}>
            <div className="key-data__wrapper">
                <div className="grid-container">
                    <ul className="grid-row">
                        {cards && cards.map((card, i) => (
                            <li key={i} className={`key-data__card c-xl-${cards.length > 3 ? 3 : 4} c-lg-${cards.length > 3 ? 6 : 4} c-md-${cards.length > 3 ? 6 : 4} c-sm-${cards.length > 3 ? 6 : 12}`}>
                                <h3 className='key-data__title'>{card.title}</h3>
                                <h4 className='key-data__subtitle'>{card.subtitle}</h4>
                                <p className='key-data__text'>{card.text}</p>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
}
