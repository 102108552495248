import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

export default function BodyScalability() {
  const [isScalable, setIsScalable] = useState(true);

  useEffect(() => {
    document.body.classList.toggle('scalable', isScalable);
    // trigger a screen resize event to force the macros height adjustment
    window.dispatchEvent(new Event('resize'));
  }, [isScalable]);

  const toggleBodyScale = () => {
    setIsScalable(!isScalable);
  };

  return (
    <>
      <button
        onClick={toggleBodyScale}
        className={clsx('body-scalability-button', { active: isScalable })}
      >
        {isScalable ? 'Disable Scale' : 'Enable Scale'}
      </button>
    </>
  );
}
