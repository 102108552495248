import React from 'react';
import clsx from 'clsx';

// components
import Image from '../Image';

// prop types
interface ReviewAuthorProps {
    imgSrc?: string
    imgAlt?: string
    authorName?: string
    authorTitle?: string
    className?: string,
}

export default function ReviewAuthor({
    imgSrc,
    imgAlt,
    authorName,
    authorTitle,
    className
}: ReviewAuthorProps) {
    const classes = clsx(
        'review-author',
        className
    )
    return (
            <div className={classes}>
                <figure className="review-author__image">
                    {imgSrc && <Image
                        src={imgSrc}
                        width={80}
                        alt={imgAlt}
                    />}
                </figure>
                <div className="review-author__info">
                    <h4 className="review-author__name">{authorName}</h4>
                    <p className="review-author__title">{authorTitle}</p>
                </div>
            </div>
    );
}