// libraries
import React, { useState } from 'react'
import clsx from 'clsx'

export default function Guidelines() {

    // show / hide grid
    const [isGridVisible, setIsGridVisible] = useState(false)

    const showHideGrid = () => {
		setIsGridVisible(!isGridVisible)
	}

    return (
        <>

            <button onClick={showHideGrid} className='guidelines-button'>
                Grid
            </button>

            <div className='breakpoint-indicator'></div>

            <div className={clsx('guidelines-grid', isGridVisible && 'visible')}>
                <div className='grid-container'>
                    <div className='grid-row'>
                        
                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                        <div className='c-md-1 c-xs-2'>
                            <div className='block'></div>
                        </div>

                    </div>
                </div>
            </div>

        </>
    )
}