import React from 'react';
import clsx from 'clsx';

// prop types
interface RichTextProps {
    content?: string
    className?: string
}

export default function RichText({
    content,
    className
}: RichTextProps) {
    const classes = clsx(
        'rich-text',
        className,
    );
    return (
        <div className={classes} dangerouslySetInnerHTML={{ __html: content }} suppressHydrationWarning />
    );
}
