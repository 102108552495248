import React from 'react';
import SelectButton from '../SelectButton';
import Section from '../Section';

interface FooterProps {
    logoImage?: string,
    logoHref?: string,
    mainNav?: Array<{
        title: string,
        links: Array<{
            name: string,
            href: string,
            external?: boolean
        }>
    }>,
    copyright?: Array<{ textLine: string }>,
    bottomNav?: Array<{ name: string, href: string, external?: boolean }>,
    countryOptions?: Array<{ value: string, label: string }>
    countryOptionsLabel?: string
    languageOptions?: Array<{ value: string, label: string }>
    languageOptionsLabel?: string
    socialMediaButtons?: Array<{ name: string, href: string, src: string }>
}

export default function Footer({
    logoImage,
    logoHref,
    mainNav,
    copyright,
    bottomNav,
    countryOptions,
    countryOptionsLabel = 'Select country',
    languageOptions,
    languageOptionsLabel = 'Select language',
    socialMediaButtons
}: FooterProps) {
    return (
        <Section className='footer' customTag='footer' dividerTop={true} dividerBottom={false}>
            <div className="grid-container footer__container">
                <div className="grid-row footer__row footer__row--top">
                    {logoImage && (
                        <figure className="c-lg-4 c-md-12 footer__logo">
                            <a href={logoHref}>
                                <img src={logoImage} alt="99x" />
                            </a>
                        </figure>
                    )}

                    {mainNav && mainNav.map((item, i) => (
                        <div key={i} className="c-lg-2 c-md-3 c-xs-6 footer__nav">
                            <h2 className="footer__title">{item.title}</h2>
                            <ul>
                                {item.links.map((link, j) => (
                                    <li key={j}>
                                        <a href={link.href} className="footer__link" target={link.external ? "_blank" : "_self"} rel={link.external ? "noopener noreferrer" : undefined}>
                                            {link.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                <div className="grid-row footer__row footer__row--bottom">
                    {copyright && (
                        <div className="c-xl-4 c-lg-12 footer__copyright">
                            {copyright.map((item, i) => (
                                <p key={i} className="mono-xs">
                                    {item.textLine}
                                </p>
                            ))}
                        </div>
                    )}

                    {bottomNav && (
                        <ul className="c-xl-4 c-lg-4 footer__bottom-nav">
                            {bottomNav.map((item, i) => (
                                <li key={i}>
                                    <a href={item.href} target={item.external ? "_blank" : "_self"} rel={item.external ? "noopener noreferrer" : undefined}>
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}

                    <div className="c-xl-2 c-lg-4 footer__selectors">
                        {/* Country selector */}
                        {countryOptions && (
                            <SelectButton
                                options={countryOptions}
                                defaultValue={countryOptions[0]}
                                ariaLabel={countryOptionsLabel}
                                // onChange={(value) => console.log(value)}
                            />
                        )}

                        {/* Language selector */}
                        {languageOptions && (
                            <SelectButton
                                options={languageOptions}
                                defaultValue={languageOptions[0]}
                                ariaLabel={languageOptionsLabel}
                                // onChange={(value) => console.log(value)}
                            />
                        )}
                    </div>

                    {socialMediaButtons && (
                        <ul className="c-xl-2 c-lg-4 social-links footer__social">
                            {socialMediaButtons.map((item, i) => (
                                <li key={i}>
                                    <a href={item.href} target="_blank" rel="noopener noreferrer">
                                        <img src={item.src} alt={item.name} />
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </Section>
    );
}
