import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import Image from '../../Image';

interface MacroQuoteProps {
    quote?: string; // Quote text
    imgSrc?: string;
    imgAlt?: string;
    customerName?: string;
    customerTitle?: string;
    className?: string; // Additional CSS classes for styling
}

export default function MacroQuote({
    quote,
    imgSrc,
    imgAlt,
    customerName,
    customerTitle,
    className,
}: MacroQuoteProps) {
    const classes = clsx(
        'quote',
        className
    );

    return (
        <div className={classes}>
            <div className="quote__wrapper">
                <div className="grid-container">
                    <div className="grid-row">
                        <div className="c-xl-8 start-xl-5 c-lg-8 start-lg-4">
                            <blockquote className="quote__blockquote">
                                <p>{quote}</p>
                            </blockquote>
                            {(imgSrc || customerName || customerTitle) && (
                                <div className="quote__author">
                                    {imgSrc && (
                                        <figure className="quote__figure">
                                            <Image
                                                src={imgSrc}
                                                width={80}
                                                alt={imgAlt}
                                            />
                                        </figure>
                                    )}
                                    {customerName && (<p className="quote__name">{customerName}</p>)}
                                    {customerTitle && (<p className="quote__title">{customerTitle}</p>)}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
