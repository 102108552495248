import React from 'react';
import clsx from 'clsx';
import Section from '../Section';

// components

// prop types
interface NewsProps {
    sectionHeader?: React.ReactNode
    cards?: React.ReactElement[]
    className?: string,
}

export default function News({
    sectionHeader,
    cards,
    className
}: NewsProps) {
    const classes = clsx(
        'news',
        className
    )

    return (
        <Section className={classes}>
            {sectionHeader}
            <div className="grid-container">
                <div className="grid-row news__wrapper">
                    {cards && cards.map((card, index) => (
                        React.cloneElement(card, {
                            className: `c-lg-${index === 0 ? 6 : 3} c-sm-${index === 0 ? 12 : 6} xs-12`,
                            key: index,
                            layout: `${index === 0 ? 'horizontal' : 'vertical'}`
                        })
                    ))}
                </div>
            </div>
        </Section>
    );
}
