import React from 'react';
import clsx from 'clsx';

// components
import Image from '../Image';

// prop types
interface ProjectCardProps {
    href?: string;
    isExternalUrl?: boolean;
    color?: 'white'| 'primary' | 'secondary' | 'orangeTwine' | 'brownSorell' | 'greenSmoke' | 'redRust' | 'lavender'
    imgSrc?: string;
    imgSrcMedium?: string;
    imgSrcSmall?: string;
    imgAlt?: string;
    imgWidth?: number;
    label?: string;
    title?: string;
    type?: string;
    className?: string;
    strings?: any;
}

export default function ProjectCard({
    href,
    isExternalUrl = false,
    color = 'lavender',
    imgSrc,
    imgSrcMedium,
    imgSrcSmall,
    imgAlt,
    imgWidth,
    label,
    title,
    type,
    className,
    strings,
}: ProjectCardProps) {
    const classes = clsx(
        'project-card',
        `project-card--${color}`,
        className
    )
    return (
        <article className={classes} data-type={type}>
            <a href={href} target={isExternalUrl ? '_blank' : undefined} rel={isExternalUrl ? 'noopener noreferrer' : undefined} className='absolute-link project-card__link'><span className='sr-only'>{strings?.readMoreAbout} {title}</span></a>
            {imgSrc && (
                <figure className="project-card__figure">
                    <Image
                        src={imgSrc}
                        width={imgWidth}
                        srcMedium={imgSrcMedium}
                        srcSmall={imgSrcSmall}
                        alt={imgAlt}
                        sizes="(max-width: 768px) 100vw"
                    />
                </figure>
            )}
            <div className="project-card__content">
                {label && <p className="project-card__label">{label}</p>}
                {title && <h3 className="project-card__title">{title}</h3>}
            </div>
        </article>
    );
}
