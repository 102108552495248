import React from 'react';
import clsx from 'clsx';

// components
import Section from '../Section';
import LabelText from '../LabelText';
import Image from '../Image';
import Button from '../Button';

interface JobItem {
    title?: string;
    description?: string;
    buttons?: React.ReactElement<typeof Button>[];
}

// prop types
interface VacanciesProps {
    labelText?: string;
    bulletColor?: 'black' | 'accent-1' | 'accent-2' | 'accent-3' | 'accent-4' | 'accent-5' | 'accent-6';
    title?: string;
    subtitle?: string;
    imgSrc? : string;
    imgSrcMedium? : string;
    imgSrcSmall? : string;
    imgAlt? : string;
    imgWidth? : number;
    imgCaption?: string;
    JobItems?: JobItem[];
    allVacanciesButton?: React.ReactElement<typeof Button>;
    // section props
    section?: {
        dividerTop?: boolean;
        dividerBottom?: boolean;
    }
    className?: string;
}

export default function Vacancies({
    labelText,
    bulletColor = 'accent-1',
    title,
    subtitle,
    imgSrc,
    imgSrcMedium,
    imgSrcSmall,
    imgAlt,
    imgWidth,
    imgCaption,
    JobItems,
    allVacanciesButton,
    section,
    className,
}: VacanciesProps) {
    const classes = clsx(
        'vacancies',
        className,
    );
    return (
        <Section className={classes} dividerTop={section?.dividerTop} dividerBottom={section?.dividerBottom}>
            <div className="grid-container">
                <div className="vacancies__wrapper">
                    <div className="grid-row">
                        <div className='vacancies__content c-lg-6 c-md-7'>
                            {labelText && (
                                <LabelText text={labelText} bulletColor={bulletColor} className="vacancies__label" />
                            )}
                            {title && <h2 className="vacancies__title">{title}</h2>}
                            {subtitle && <p className='vacancies__subtitle'>{subtitle}</p>}
                            {JobItems && (
                                <ul className="vacancies__list">
                                    {JobItems.map((item, index) => (
                                        <li className="vacancies__item" key={index}>
                                            <div className="vacancies__item-content">
                                                {item.title && <h3 className="vacancies__item-title">{item.title}</h3>}
                                                {item.description &&
                                                    <p className="vacancies__item-description">
                                                        {item.description}
                                                    </p>}
                                            </div>
                                            {item.buttons &&(
                                                <div className="vacancies__item-buttons">
                                                    {item.buttons.map((button, buttonIndex) => (
                                                        <React.Fragment key={buttonIndex}>
                                                            {button}
                                                        </React.Fragment>
                                                    ))}
                                                </div>
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            {allVacanciesButton && (
                                <div className="vacancies__all-btn">
                                    {allVacanciesButton}
                                </div>
                            )}
                        </div>
                        {imgSrc && (
                            <div className="vacancies__figure-wrapper c-lg-6 c-md-5">
                                <figure className='vacancies__figure'>
                                    <Image
                                        src={imgSrc}
                                        width={imgWidth}
                                        srcMedium={imgSrcMedium}
                                        srcSmall={imgSrcSmall}
                                        alt={imgAlt}
                                        sizes="(max-width: 768px) 100vw"
                                    />
                                    {imgCaption && <figcaption className="vacancies__caption caption">{imgCaption}</figcaption>}
                                </figure>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Section>
    );
}
