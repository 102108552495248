import React from 'react';
import clsx from 'clsx';

// prop types
interface ErrorSectionProps {
    logoSrc?: string;
    title?: string;
    subtitle?: string;
    buttons?: React.ReactNode[];
    className?: string;
}

export default function ErrorSection({
    logoSrc,
    title,
    subtitle,
    buttons,
    className,
}: ErrorSectionProps) {
    const classes = clsx(
        'error-section',
        className
    )
    return (
        <div className={classes}>
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-xl-6 start-xl-4 c-lg-8 start-lg-3 error-section__content">
                        {logoSrc && (
                            <img src={logoSrc} className="error-section__logo" alt="99x" />
                        )}
                        {title && (
                            <h1 className="error-section__title">{title}</h1>
                        )}
                        {(subtitle) && (
                            <p className="error-section__subtitle">{subtitle}</p>
                        )}
                        {buttons && (
                            <div className="error-section__buttons">
                                {buttons.map((button, index) => (
                                    <React.Fragment key={index}>
                                        {button}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}
