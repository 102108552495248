import React from 'react';
import clsx from 'clsx';

// components
import Image from '../Image';
import Button from '../Button';
import { ArrowRight } from 'lucide-react';

// prop types
interface CardProps {
    href?: string;
    isExternalUrl?: boolean;
    buttonText?: string;
    imgSrc?: string;
    imgSrcMedium?: string;
    imgSrcSmall?: string;
    imgAlt?: string;
    imgWidth?: number;
    title?: string;
    ingress?: string;
    type?: string;
    referenceTypes?: string[];
    className?: string;
    isChildrenListCard?: boolean;
}

export default function Card({
    href,
    isExternalUrl = false,
    buttonText,
    imgSrc,
    imgSrcMedium,
    imgSrcSmall,
    imgAlt,
    imgWidth,
    title,
    ingress,
    type,
    referenceTypes,
    className,
    isChildrenListCard
}: CardProps) {
    const classes = clsx(
        `${isChildrenListCard ? 'card children-list-card' : 'card'}`,
        className
    )
    return (
        <article className={classes} data-type={type} data-reference-types={referenceTypes}>
            {imgSrc && (
                <figure className="card__figure">
                    <Image
                        src={imgSrc}
                        width={imgWidth}
                        srcMedium={imgSrcMedium}
                        srcSmall={imgSrcSmall}
                        alt={imgAlt}
                        sizes="(max-width: 768px) 100vw"
                    />
                </figure>
            )}
            <div className="card__content">
                {title && <h3 className="card__title">{title}</h3>}
                {ingress && <p className="card__ingress">{ingress}</p>}
                {buttonText && (
                    <Button
                        className="card__button"
                        href={href}
                        target={isExternalUrl ? '_blank' : undefined}
                        rel={isExternalUrl ? 'noopener noreferrer' : undefined}
                        text={buttonText}
                        size='sm'
                        variant='secondary'
                        iconAfter={<ArrowRight />}
                        absoluteLink={true}
                    />
                )}
            </div>
        </article>
    );
}
