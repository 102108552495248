import React from 'react';
import clsx from 'clsx';
import Section from '../Section';

// prop types
interface ReadMoreProps {
    title?: string;
    readMoreCards?: React.ReactNode[];

    // section props
    section?: {
        dividerTop?: boolean
        dividerBottom?: boolean
        articleGrid?: boolean
    }
    className?: string;
}

export default function ReadMore({
    title,
    readMoreCards,
    section = {articleGrid: true},
    className
}: ReadMoreProps) {
    const classes = clsx(
        'read-more',
        className
    )
    return (
        <Section
            className={classes}
            dividerBottom={section?.dividerBottom}
            dividerTop={section?.dividerTop}
            articleGrid={section?.articleGrid}
        >
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-xl-6 start-xl-5 c-lg-8 start-lg-4">
                        {title && (
                            <header className="read-more__header">
                                <h2 className="read-more__title">{title}</h2>
                            </header>
                        )}
                        {readMoreCards && readMoreCards.length > 0 && (
                            <ul className='read-more__list'>
                                {readMoreCards.map((card, index) => (
                                    <li key={index}>
                                        {card}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                </div>
            </div>
        </Section>
    );
}
