import React from 'react';
import clsx from 'clsx';

// prop types
interface HeroProps {
    title?: string;
    intro?: string;
    buttons?: React.ReactNode[];
    className?: string;
}

export default function Hero({
    title,
    intro,
    buttons,
    className
}: HeroProps) {
    const classes = clsx(
        'hero',
        className
    )
    return (
        <section className={classes}>
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-xl-8 start-xl-5 c-lg-9 start-lg-4 hero__content">
                        {title && (
                            <h1 className="hero__title">{title}</h1>
                        )}
                        {intro && (
                            <p className="hero__intro">{intro}</p>
                        )}
                        {buttons && (
                            <div className="hero__buttons">
                                {buttons.map((button, index) => (
                                    <React.Fragment key={index}>
                                        {button}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}
