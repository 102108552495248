import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import Select, { ActionMeta } from 'react-select';

// prop types
interface SelectButtonProps {
    className?: string;
    name?: string;
    id?: string;
    options: Array<{ value: string; label: string }>;
    defaultValue?: { value: string; label: string };
    onChange?: (newValue: {
        value: string;
        label: string;
    }, actionMeta: ActionMeta<{
        value: string;
        label: string;
    }>) => void 
    ariaLabel?: string;
}

export default function SelectButton({
    className,
    name,
    id,
    options,
    defaultValue,
    onChange,
    ariaLabel
}: SelectButtonProps) {
    const [domLoaded, setDomLoaded] = useState(false);

    useEffect(() => {
        // Simulate DOM loading
        setDomLoaded(true);
    }, []);

    const handleChange = (selectedOption: any, actionMeta: ActionMeta<any>) => {
        if (onChange) {
            onChange(selectedOption, actionMeta);
        }
    };

    if (!defaultValue) {
        defaultValue = options[0];
    }

    if(!ariaLabel) {
        ariaLabel = 'Select an option'
    }

    return (
        domLoaded ? (
            <>
                <Select
                    className={clsx('select-button', className)}
                    classNamePrefix='select-button'
                    name={name}
                    id={id}
                    options={options}
                    defaultValue={defaultValue}
                    onChange={handleChange}
                    isSearchable={false}
                    aria-label={ariaLabel}
                    menuPlacement='auto'
                />
            </>
        ) : null
    );
}
