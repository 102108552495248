import React, { useState } from 'react';
import clsx from 'clsx';
import Section from '../Section';
import Tag from '../Tag';

interface FilterOption {
    value: string;
    label: string;
}

interface Filters {
    type: string;
    options: FilterOption[];
}

interface ReferenceProps {
    heroSection?: React.ReactNode;
    cards?: React.ReactElement<{ referenceTypes: string[] }>[];
    filters?: Filters[];
    strings?: any;
    className?: string;
}

export default function Reference({
    heroSection,
    cards,
    filters,
    strings,
    className
}: ReferenceProps) {

    const [selectedFilters, setSelectedFilters] = useState<Record<string, string | null>>({});

    const handleFilterClick = (filterType: string, filterValue: string) => {
        setSelectedFilters((currentFilters) => {
            const isFilterSelected = currentFilters[filterType] === filterValue;
    
            // If the filter is already selected, deselect it by setting it to null
            // Otherwise, set the clicked filter as the selected one for its type
            return {
                ...currentFilters,
                [filterType]: isFilterSelected ? null : filterValue,
            };
        });
    };
    
    const filteredCards = cards?.filter((card) => {
        // If no filters are selected, show all cards
        const selectedValues = Object.values(selectedFilters).filter(Boolean);
        if (selectedValues.length === 0) return true;

        // Check if the card should be displayed based on any selected filter
        return selectedValues.every((selectedValue) =>
            card.props.referenceTypes.includes(selectedValue)
        );
    });

    const classes = clsx('reference', className);

    return (
        <Section className={classes}>
            {heroSection}
            {filters && filters.length > 0 && (
                <div className="grid-container reference__filters">
                    <div className="grid-row">
                        <div className="c-xl-8 start-xl-5 c-lg-9 start-lg-4">
                            <div className="grid-row reference__filters-wrapper">
                                {filters.map((filter, filterIndex) => (
                                    <div key={filterIndex} className="c-md-6">
                                        <h3 className="reference__filter-title">{filter.type}</h3>
                                        <ul className="reference__filter-list">
                                            {filter.options.map((option, optionIndex) => (
                                                <li key={optionIndex}>
                                                    <Tag
                                                        className='reference__filter-item'
                                                        isActive={selectedFilters[filter.type] === option.value}
                                                        text={option.label}
                                                        onClick={() => handleFilterClick(filter.type, option.value)}
                                                    />
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="grid-container">
                {filteredCards && filteredCards.length > 0 && (
                    <div className="grid-row reference__wrapper">
                        {filteredCards.map((card, index) => (
                            <div key={index} className="reference__item c-lg-3 c-md-6 c-xs-6">
                                {card}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </Section>
    );
}
