import React from 'react';
import clsx from 'clsx';

// prop types
interface TagProps {
    text?: string; // Primary content of the button
    href?: string; // Defines a hyperlink location for the button
    onClick?: () => void; // Function to execute on button click
    disabled?: boolean; // Disables button interaction
    isActive?: boolean;
    className?: string; // Additional CSS classes for styling
}

export default function Tag({
    text,
    href,
    onClick,
    disabled,
    isActive = false,
    className,
}: TagProps) {
    const classes = clsx(
        'tag',
        isActive && 'tag--active',
        className
    );

    return (
        <button {...{ onClick, disabled, className: classes}}>
            {text && <span className='tag__text'>{text}</span>}
        </button>
    );
}
