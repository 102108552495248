import React from 'react';
import clsx from 'clsx';
import StarRating from '../StarRating';

// components
import RichText from '../RichText';
import ReviewAuthor from '../ReviewAuthor';

// prop types
interface ReviewCardProps {
    color?: 'white'| 'primary' | 'secondary' | 'orangeTwine' | 'brownSorell' | 'greenSmoke' | 'redRust' | 'lavender'
    href?: string;
    isExternalUrl?: boolean
    reviewText?: string
    reviewRichText?: string
    reviewValue?: 1 | 2 | 3 | 4 | 5
    totalStars?: number
    imgSrc?: string
    imgAlt?: string
    authorName?: string
    authorTitle?: string
    type?: string
    className?: string,
    strings?: any
}

export default function ReviewCard({
    color = 'greenSmoke',
    href,
    isExternalUrl = false,
    reviewText,
    reviewRichText,
    reviewValue = 5,
    totalStars = 5,
    imgSrc,
    imgAlt,
    authorName,
    authorTitle,
    type,
    className,
    strings,
}: ReviewCardProps) {
    const classes = clsx(
        'review-card',
        `review-card--${color}`,
        className
    )
    return (
        <article className={classes} data-type={type}>
            {href && <a href={href} target={isExternalUrl ? '_blank' : undefined} rel={isExternalUrl ? 'noopener noreferrer' : undefined} className='absolute-link review-card__link'><span className='sr-only'>{strings?.readMoreAbout} {authorName}</span></a>}
            
            <StarRating rating={reviewValue} totalStars={totalStars} />
            
            {/* To be used when the text comes from an htmlArea field */}
            {reviewRichText &&
                <RichText
                    className="review-card__rich-text"
                    content={reviewRichText}
                />
            }

            {/* To be used when the text comes from a textArea field */}
            {reviewText && !reviewRichText && <h3 className="review-card__text">{reviewText}</h3>}

            {/* Review author component */}
            <ReviewAuthor
                imgSrc={imgSrc}
                imgAlt={imgAlt}
                authorName={authorName}
                authorTitle={authorTitle}
            />
        </article>
    );
}