import React, { useEffect } from 'react';
import clsx from 'clsx';
import Section from '../Section';

// prop types
interface HubspotFormProps {
    id: string;
    className?: string;
}

export default function hubspotForm({
    id,
    className
}: HubspotFormProps) {
    const classes = clsx(
        'hubspot-form',
        className
    )
    
    return (
        <Section className={classes}>
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-lg-12">
                        <div id={id} className='hubspot-form__container'></div>
                    </div>
                </div>
            </div>
        </Section>
    );
}
