import React from 'react';
import clsx from 'clsx';

// components
import Image from '../Image';

// prop types
interface LabelTextProps {
    bulletColor?: 'black' | 'accent-1' | 'accent-2' | 'accent-3' | 'accent-4' | 'accent-5' | 'accent-6'
    text?: string;
    className?: string;
}

export default function LabelText({
    bulletColor = 'black',
    text,
    className
}: LabelTextProps) {
    const classes = clsx(
        'label-text',
        `label-text--${bulletColor}`,
        className
    )
    return (
        <div className={classes}>
            <svg className="label-text__bullet" width="8" height="9" viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="4" cy="4.5" r="3" stroke="black" strokeWidth="2" />
            </svg>
            <p className="label-text__text">{text}</p>
        </div>
    );
}
