import React from 'react';
import clsx from 'clsx';

// components
import SectionHeader from '../SectionHeader';
import RichTextSection from '../RichTextSection';

// prop types
interface ReviewBlockProps {
    title?: string;
    intro?: string;
    reviewValue?: 1 | 2 | 3 | 4 | 5
    reviewAuthorImgAlt?: string
    reviewAuthorImgSrc?: string
    reviewAuthorName?: string
    reviewAuthorTitle?: string
    content?: string
    className?: string;
}

export default function ReviewBlock({
    title,
    intro,
    reviewValue,
    reviewAuthorImgAlt,
    reviewAuthorImgSrc,
    reviewAuthorName,
    reviewAuthorTitle,
    content,
    className
}: ReviewBlockProps) {
    const classes = clsx(
        'review-block',
        'wrapper-block',
        className
    )
    return (
        <section className={classes}>
            <SectionHeader
                layout='horizontal'
                title={title}
                subtitle={intro}
                reviewValue={reviewValue}
                reviewAuthorImgSrc={reviewAuthorImgSrc}
                reviewAuthorImgAlt={reviewAuthorImgAlt}
                reviewAuthorName={reviewAuthorName}
                reviewAuthorTitle={reviewAuthorTitle}
            />
            <RichTextSection
                content={content}
            />
        </section>
    );
}
