import React from 'react'
import clsx from 'clsx'

// prop types
export interface SectionProps {
    children: React.ReactNode
    dividerTop?: boolean
    dividerBottom?: boolean
    className?: string
    id?: string
    customTag?: string
    articleGrid?: boolean
}

export default function Section({
    children,
    dividerTop,
    dividerBottom = true,
    className,
    id,
    customTag = 'section',
    articleGrid = false
}: SectionProps) {
    const CustomTag = customTag as keyof JSX.IntrinsicElements;

    return (
        <CustomTag
            id={id}
            className={clsx(
                'section',
                articleGrid && 'section--article-grid',
                className
            )}
        >
            {dividerTop && (<div className='grid-container section__divider section__divider--top' />)}
            {children}
            {dividerBottom && (<div className='grid-container section__divider section__divider--bottom' />)}
        </CustomTag>
    )
}
