// libraries
import React, { useEffect } from 'react'
import clsx from 'clsx'

// components
import Guidelines from '/react4xp/components/Guidelines'
import BodyScalability from '/react4xp/components/BodyScalability'

// Drawer component
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'

// images
import { ArrowDown, Menu, X } from 'lucide-react'
import Button from '../Button'
import SelectButton from '../SelectButton'

// props
interface HeaderProps {
    isPrototype?: boolean,
    logoImage?: string,
    logoHref?: string,
    topNav?: Array<{ name: string, href: string, external?: boolean }>, // The centered menu on desktop
    menuNav?: Array<{ name: string, href: string, external?: boolean }>, // The menu on mobile
    extraNav?: Array<{ name: string, href: string, external?: boolean }>, // The other links on the collapsed menu
    extraLink?: { name: string, href: string, external?: boolean }, // Contact link on desktop
    menuButtonLabel?: string,
    skipToMain?: string,
    countryOptions?: Array<{ value: string, label: string }>
    countryOptionsLabel?: string
    languageOptions?: Array<{ value: string, label: string }>
    languageOptionsLabel?: string
    socialMediaButtons?: Array<{ name: string, href: string, src: string }>
    menuBottomLinks?: Array<{ name: string, href: string, external?: boolean }>
    selectedCountry?: string,
    selectedCountryLabel?: string,
    selectedLanguage?: string
    strings?: any
}

export default function Header({
    isPrototype,
    logoImage,
    logoHref,
    topNav,
    menuNav,
    extraNav,
    extraLink,
    menuButtonLabel,
    skipToMain,
    countryOptions,
    countryOptionsLabel = 'Select country',
    languageOptions,
    languageOptionsLabel = 'Select language',
    socialMediaButtons,
    menuBottomLinks,
    selectedCountry,
    selectedCountryLabel,
    selectedLanguage,
    strings,
}: HeaderProps) {
    menuButtonLabel = menuButtonLabel || 'Menu'

    const [isOpen, setIsOpen] = React.useState(false)
    const toggleDrawer = () => {
        setIsOpen((prevState) => !prevState)
    }

    // Close on Esc press
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape' && isOpen) {
                setIsOpen(false)
            }
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => document.removeEventListener('keydown', handleKeyDown)
    }, [isOpen])

    return (
        <>
            {isPrototype && <Guidelines />}
            {isPrototype && <BodyScalability />}
            <header className='header'>
                <Button href='#main' className='skip-to-main' text={skipToMain} variant='default' size='md' iconAfter={<ArrowDown />} />
                <div className="grid-container">
                    <div className="grid-row">
                        <div className="c-lg-3 header__section header__section--left">
                            {/* Logo */}
                            <a href={logoHref} className='header__logo'>
                                <img src={logoImage} alt='99X' />
                            </a>

                            {/* Country selector */}
                            {countryOptions && (
                                <SelectButton
                                    options={countryOptions}
                                    defaultValue={countryOptions.find(countryObj => countryObj.label === selectedCountryLabel)}
                                    ariaLabel={countryOptionsLabel}
                                    onChange={target => {
                                        if (target.value === 'global/en') {
                                            window.location.href = window.location.origin
                                        } else if (target.value) {
                                            window.location.href = window.location.origin + `/${target.value}`
                                        }
                                    }}

                                />
                            )}
                        </div>
                        <div className="c-xl-8 start-xl-5 c-lg-9 start-lg-4 header__section header__section--right">
                            {/* Top menu */}
                            {topNav && topNav.length > 0 && (
                                <nav className="header__nav">
                                    <ul>
                                        {topNav.map((item, i) => (
                                            <li key={i}>
                                                <a
                                                    href={item.href}
                                                    target={item.external ? '_blank' : undefined}
                                                    rel={item.external ? 'noreferrer' : undefined}
                                                    className={clsx('header__link')}
                                                >
                                                    {item.name}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </nav>
                            )}

                            {extraLink && (
                                <a
                                    href={extraLink.href}
                                    target={extraLink.external ? '_blank' : undefined}
                                    rel={extraLink.external ? 'noreferrer' : undefined}
                                    className='header__link'
                                >
                                    {extraLink.name}
                                </a>
                            )}

                            {/* Language selector */}
                            {languageOptions &&
                                <SelectButton
                                options={languageOptions}
                                defaultValue={languageOptions.find(languageObj => languageObj.value === selectedLanguage)}
                                ariaLabel={languageOptionsLabel}
                                onChange={(target) => {
                                    if (selectedCountry && target.value && languageOptions.length > 1) {
                                    if (target.value === 'no') {
                                        selectedCountry = 'nordics'
                                    }
                                    window.location.href = window.location.origin + (`/${selectedCountry}/${target.value}`)
                                    }
                                }}
                                />
                            }

                            {/* Menu button */}
                            <button className='header__menu-button' aria-label={menuButtonLabel} onClick={toggleDrawer} >
                                <Menu />
                            </button>
                        </div>
                    </div>
                </div>
                {/* Header menu (Drawer) */}
                <Drawer
                    open={isOpen}
                    onClose={toggleDrawer}
                    direction='right'
                    className='header__menu'
                    overlayOpacity={0}
                    size={''}
                    lockBackgroundScroll={true}
                    customIdSuffix={'_menu'}
                    style={{ width: '100%' }}
                >
                    <div className="header__menu-wrapper">
                        {/* Close button */}
                        <button className='header__menu-close' onClick={toggleDrawer} aria-label={strings?.close}>
                            <X />
                        </button>

                        {menuNav && menuNav.length > 0 && (
                            <ul className="header__menu-links">
                                {menuNav.map((item, i) => (
                                    <li key={i}>
                                        <a
                                            href={item.href}
                                            target={item.external ? '_blank' : undefined}
                                            rel={item.external ? 'noreferrer' : undefined}
                                            className={clsx('header__link')}
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {extraNav && extraNav.length > 0 && (
                            <ul className='header__menu-links header__menu-links--small'>
                                {extraNav.map((item, i) => (
                                    <li key={i}>
                                        <a
                                            href={item.href}
                                            target={item.external ? '_blank' : undefined}
                                            rel={item.external ? 'noreferrer' : undefined}
                                            className='header__link'
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}

                        {/* Social media buttons */}
                        {socialMediaButtons && socialMediaButtons.length > 0 && (
                            <ul className="social-links header__social">
                                {socialMediaButtons.map((item, i) => (
                                    item.href && (
                                      <li key={i}>
                                        <a
                                          href={item.href}
                                          target='_blank'
                                          aria-label={item.name}
                                        >
                                          <img src={item.src} aria-hidden='true' alt={item.name} />
                                        </a>
                                      </li>
                                    )
                                ))}
                            </ul>
                        )}

                        {/* Bottom links */}
                        {menuBottomLinks && menuBottomLinks.length > 0 && (
                            <ul className="header__menu-links header__menu-links--bottom">
                                {menuBottomLinks.map((item, i) => (
                                    <li key={i}>
                                        <a
                                            href={item.href}
                                            target={item.external ? '_blank' : undefined}
                                            rel={item.external ? 'noreferrer' : undefined}
                                            className='header__link'
                                        >
                                            {item.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        )}
                    </div>

                </Drawer>
            </header>
        </>
    )
}
