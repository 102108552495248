import React from 'react';
import clsx from 'clsx';

// components
import LabelText from '../LabelText';
import Button from '../Button';
import { ArrowLeft, ArrowRight } from 'lucide-react';
import StarRating from '../StarRating';
import ReviewAuthor from '../ReviewAuthor';

// prop types
interface SectionHeaderProps {
    layout?: 'vertical' | 'horizontal';
    title?: string;
    subtitle?: string;
    labelText?: string;
    bulletColor?: 'black' | 'accent-1' | 'accent-2' | 'accent-3' | 'accent-4' | 'accent-5' | 'accent-6';
    buttons?: React.ReactNode[];
    isInCarousel?: boolean;
    onPrevSlide?: () => void;
    onNextSlide?: () => void;
    isPrevDisabled?: boolean;
    isNextDisabled?: boolean;
    reviewValue?: 1 | 2 | 3 | 4 | 5
    reviewAuthorImgSrc?: string
    reviewAuthorImgAlt?: string
    reviewAuthorName?: string
    reviewAuthorTitle?: string
    className?: string;
    strings?: any;
}

export default function SectionHeader({
    layout = 'vertical',
    title,
    subtitle,
    labelText,
    bulletColor = 'accent-1',
    buttons,
    isInCarousel = false,
    onPrevSlide,
    onNextSlide,
    isPrevDisabled = false,
    isNextDisabled = false,
    reviewValue,
    reviewAuthorImgSrc,
    reviewAuthorImgAlt,
    reviewAuthorName,
    reviewAuthorTitle,
    className,
    strings,
}: SectionHeaderProps) {
    const classes = clsx(
        'section-header',
        `section-header--${layout}`,
        className
    )
    return (
        <div className={classes}>
            <div className="grid-container">
                <div className="grid-row">
                    {labelText && (
                        <div className="c-lg-12 section-header__col">
                            <LabelText text={labelText} bulletColor={bulletColor} className="section-header__label" />
                        </div>
                    )}
                    {title && (
                        <div className={`section-header__col ${layout === 'vertical' ? 'c-lg-12' : 'c-lg-6'}`}>
                            <h2 className="section-header__title">{title}</h2>
                        </div>
                    )}
                    {(subtitle || reviewValue || reviewAuthorName || buttons ) && (
                        <div className={`c-lg-6 section-header__col ${layout === 'vertical' ? 'start-lg-1' : ''}`}>
                            {subtitle && <p className="section-header__subtitle">{subtitle}</p>}

                            {/* Review stars */}
                            {reviewValue && <StarRating rating={reviewValue} totalStars={5} colorMode='light' />}

                            {/* Review author */}
                            {reviewAuthorName &&
                                <ReviewAuthor
                                imgSrc={reviewAuthorImgSrc}
                                imgAlt={reviewAuthorImgAlt}
                                authorName={reviewAuthorName}
                                authorTitle={reviewAuthorTitle}
                                />
                            }

                            {buttons && (
                                <div className="section-header__buttons">
                                    {buttons.map((button, index) => (
                                        <React.Fragment key={index}>
                                            {button}
                                        </React.Fragment>
                                    ))}
                                </div>
                            )}
                        </div>
                    )}
                    {isInCarousel && (
                        <div className="c-lg-6 section-header__carousel-buttons">
                            <Button
                                variant='secondary'
                                size='md'
                                iconBefore={<ArrowLeft />}
                                ariaLabel={strings?.previousSlide}
                                onClick={onPrevSlide}
                                disabled={isPrevDisabled}
                            />
                            <Button
                                variant='secondary'
                                size='md'
                                iconBefore={<ArrowRight />}
                                ariaLabel={strings?.nextSlide}
                                onClick={onNextSlide}
                                disabled={isNextDisabled}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
