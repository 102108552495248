import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

// components
import Section from '../Section';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

// prop types
interface CarouselProps {
    sectionHeader?: React.ReactElement
    slides?: React.ReactNode[] // cards
    slidesPerView?: 1 | 2 | 3 | 4 | 5 | 6
    containerPadding?: number
    containerPaddingMd?: number
    className?: string;
}

export default function Carousel({
    sectionHeader,
    slides = [],
    slidesPerView = 4,
    containerPadding = 32,
    containerPaddingMd = 16,
    className
}: CarouselProps) {
    const [isCarouselInitialized, setIsCarouselInitialized] = useState(false);

    const classes = clsx(
        'carousel',
        className,
        { 'carousel--initialized': isCarouselInitialized }
    );

    let spaceBetween = 32;
    if (slidesPerView >= 4) spaceBetween = 16;

    slidesPerView = slidesPerView + 0.18;

    const swiperRef = useRef(null);

    const [isBeginning, setIsBeginning] = useState(true);
    const [isEnd, setIsEnd] = useState(false);

    const handlePrevSlide = () => {
        if (swiperRef.current) swiperRef.current.slidePrev();
    };

    const handleNextSlide = () => {
        if (swiperRef.current) swiperRef.current.slideNext();
    };

    const onSwiper = (swiper) => {
        swiperRef.current = swiper;
        setIsBeginning(swiper.isBeginning);
        setIsEnd(swiper.isEnd);
    };

    const updateCarouselState = () => {
        const width = window.innerWidth;
        let breakpointSlidesPerView = slidesPerView;
        let isInitialized = false;

        if (width < 480) {
            breakpointSlidesPerView = 1.4;
        } else if (width < 768) {
            breakpointSlidesPerView = 2.4;
        } else if (width < 992) {
            breakpointSlidesPerView = 3.35;
        } else if (width < 1440) {
            breakpointSlidesPerView = slidesPerView + 0.18;
        }

        isInitialized = slides.length > breakpointSlidesPerView;
        setIsCarouselInitialized(isInitialized);
    };

    useEffect(() => {
        updateCarouselState();
        window.addEventListener('resize', updateCarouselState);
        return () => window.removeEventListener('resize', updateCarouselState);
    }, [slides, slidesPerView]);

    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.on('reachBeginning', () => setIsBeginning(true));
            swiperRef.current.on('reachEnd', () => setIsEnd(true));
            swiperRef.current.on('fromEdge', () => {
                setIsBeginning(swiperRef.current.isBeginning);
                setIsEnd(swiperRef.current.isEnd);
            });
        }
    }, []);

    return (
        <Section className={classes}>
            {sectionHeader && (
                React.cloneElement(sectionHeader, {
                    isInCarousel: true,
                    onPrevSlide: handlePrevSlide,
                    onNextSlide: handleNextSlide,
                    isPrevDisabled: isBeginning,
                    isNextDisabled: isEnd
                })
            )}
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-lg-12">
                        <Swiper
                            onSlideChange={() => {
                                setIsBeginning(swiperRef.current.isBeginning);
                                setIsEnd(swiperRef.current.isEnd);
                            }}
                            modules={[Pagination]}
                            pagination={{
                                clickable: true
                            }}
                            mousewheel={{
                                forceToAxis: true
                            }}
                            onSwiper={onSwiper}
                            spaceBetween={spaceBetween}
                            slidesOffsetBefore={isCarouselInitialized ? containerPadding : 0}
                            slidesOffsetAfter={isCarouselInitialized ? containerPadding : 0}
                            breakpoints={{
                                0: {
                                    slidesPerView: 1.4,
                                    spaceBetween: 16
                                },
                                480: {
                                    slidesPerView: 2.4,
                                    spaceBetween: 16
                                },
                                768: {
                                    slidesPerView: 3.35,
                                    spaceBetween: 16,
                                },
                                992: {
                                    slidesPerView: slidesPerView + 0.18,
                                    slidesOffsetBefore: isCarouselInitialized ? containerPaddingMd : 0,
                                    slidesOffsetAfter: isCarouselInitialized ? containerPaddingMd : 0
                                },
                                1440: {
                                    slidesPerView: slidesPerView,
                                    spaceBetween: spaceBetween
                                },
                            }}
                        >
                            {slides.map((slide, index) => (
                                <SwiperSlide key={index}>{slide}</SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </Section>
    );
}
