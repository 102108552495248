import React from 'react';
import clsx from 'clsx';

// prop types
interface VideoPlayerProps {
    youtubeVideoId?: string;
    autoplay?: boolean;
    className?: string;
}

export default function VideoPlayer({
    youtubeVideoId,
    autoplay = false,
    className
}: VideoPlayerProps) {
    const classes = clsx(
        'video-player',
        className
    );
    const embedUrl = youtubeVideoId
        ? `https://www.youtube.com/embed/${youtubeVideoId}?autoplay=${autoplay ? 1 : 0}&mute=${autoplay ? 1 : 0}`
        : '';

    return (
        <div className={classes}>
            {youtubeVideoId ? (
                <iframe
                    width="100%"
                    height="auto"
                    src={embedUrl}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            ) : (
                <p>No video available</p>
            )}
        </div>
    );
}
