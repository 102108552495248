import clsx from 'clsx';
import Section from '../Section';
import React from 'react';

interface ColumnsLayoutProps {
    content1?: React.ReactNode;
    content2?: React.ReactNode;
    content3?: React.ReactNode;
    content4?: React.ReactNode;
    columns?: 1 | 2 | 3 | 4;
    className?: string;
}

export default function ColumnsLayout({
    content1,
    content2,
    content3,
    content4,
    columns = 1,
    className
}: ColumnsLayoutProps) {
    return (
        <Section
            className={clsx(
                'columns-layout',
                `columns-layout--${columns}`,
                className
            )}
        >
            <div className="grid-container">
                <div className="grid-row">
                    {columns == 1 && (
                        <div className="columns-layout__content c-xl-6 start-xl-5 c-lg-8 start-lg-4">
                            {content1}
                        </div>
                    )}

                    {columns == 2 && (
                        <>
                            <div className="columns-layout__content c-lg-6">
                                {content1}
                            </div>
                            <div className="columns-layout__content c-lg-6">
                                {content2}
                            </div>
                        </>
                    )}

                    {columns == 3 && (
                        <>
                            <div className="columns-layout__content c-lg-4">
                                {content1}
                            </div>
                            <div className="columns-layout__content c-lg-4">
                                {content2}
                            </div>
                            <div className="columns-layout__content c-lg-4">
                                {content3}
                            </div>
                        </>
                    )}

                    {columns == 4 && (
                        <>
                            <div className="columns-layout__content c-lg-3">
                                {content1}
                            </div>
                            <div className="columns-layout__content c-lg-3">
                                {content2}
                            </div>
                            <div className="columns-layout__content c-lg-3">
                                {content3}
                            </div>
                            <div className="columns-layout__content c-lg-3">
                                {content4}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </Section>
    );
}
