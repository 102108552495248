import React from 'react';
import clsx from 'clsx';
import Button from '../Button';

// prop types
interface ReadMoreCardProps {
    label?: string;
    title?: string;
    href?: string;
    isExternalUrl?: boolean;
    buttonText?: string;
    imgSrc?: string;
    imgAlt?: string;
    className?: string;
}

export default function ReadMoreCard({
    label,
    title,
    href,
    isExternalUrl = false,
    buttonText,
    imgSrc,
    imgAlt,
    className
}: ReadMoreCardProps) {
    const classes = clsx(
        'read-more-card',
        className
    )
    return (
        <article className={classes}>
            {imgSrc && (
                <figure className="read-more-card__figure">
                    <img src={imgSrc} alt={imgAlt} />
                </figure>
            )}
            <div className="read-more-card__content">
                {label && <p className="read-more-card__label">{label}</p>}
                <h3 className="read-more-card__title">{title}</h3>
                {buttonText && (
                    <Button
                        className="read-more-card__button"
                        href={href}
                        target={isExternalUrl ? '_blank' : undefined}
                        rel={isExternalUrl ? 'noopener noreferrer' : undefined}
                        text={buttonText}
                        size='sm'
                        variant='default'
                        absoluteLink={true}
                    />
                )}
            </div>
        </article>
    );
}
