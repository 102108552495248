import React from 'react';
import clsx from 'clsx';

// prop types
interface ButtonProps {
    text?: string; // Primary content of the button
    href?: string; // Defines a hyperlink location for the button
    onClick?: () => void; // Function to execute on button click
    disabled?: boolean; // Disables button interaction
    variant?: 'default' | 'primary' | 'secondary' | 'ghost' | 'outline' | 'outline-dark'; // Visual style variations
    size?: 'sm' | 'md' | 'lg'; // Button size variations
    target?: '_blank' | undefined; // Defines link target behavior (mostly for href)
    rel?: string;
    type?: 'button'; // Defines the HTML button type (defaults to button)
    fullWidth?: boolean;
    ariaLabel?: string;
    className?: string; // Additional CSS classes for styling
    absoluteLink?: boolean;

    // Icon props
    iconBefore?: React.ReactNode;
    iconAfter?: React.ReactNode;
}

export default function Button({
    text,
    href,
    onClick,
    disabled,
    variant,
    size,
    target,
    rel,
    type,
    fullWidth,
    className,
    absoluteLink,
    iconBefore,
    iconAfter,
    ariaLabel
}: ButtonProps) {
    // check if the element is a link or a button based on the presence of href
    const Element = href ? 'a' : 'button';

    const classes = clsx(
        'btn',

        // variant
        variant === 'default' && 'btn--default',
        variant === 'primary' && 'btn--primary',
        variant === 'secondary' && 'btn--secondary',
        variant === 'ghost' && 'btn--ghost',
        variant === 'outline' && 'btn--outline',
        variant === 'outline-dark' && 'btn--outline-dark',

        // size
        size === 'sm' && 'btn--sm',
        size === 'md' && 'btn--md',
        size === 'lg' && 'btn--lg',

        // block
        fullWidth && 'btn--full-width',

        className
    );

    // Conditional logic for target prop based on Element type
    const targetProp = Element === 'a' ? target : undefined;

    return (
        <Element {...{ href, onClick, disabled, target: targetProp, rel, type, className: classes, 'aria-label': ariaLabel }}>
            {iconBefore && <span className="btn__icon btn__icon--before">{iconBefore}</span>}
            {text && <span className='btn__text'>{text}</span>}
            {iconAfter && <span className="btn__icon btn__icon--after">{iconAfter}</span>}
            {absoluteLink && <span className="absolute-link"><span className="sr-only">{text}</span></span>}
        </Element>
    );
}
