import React from 'react';
import clsx from 'clsx';

// components
import Image from '../Image';
import VideoPlayer from '../VideoPlayer';
import RichText from '../RichText';

// prop types
interface HeroBannerProps {
    title?: string;
    intro?: string;
    text?: string;
    imgSrc?: string;
    imgSrcMedium?: string;
    imgSrcSmall?: string;
    imgAlt?: string;
    imgWidth?: number;
    imgCaption?: string;
    youtubeVideoId?: string;
    autoplay?: boolean;
    buttons?: React.ReactNode[];
    className?: string;
}

export default function HeroBanner({
    title,
    intro,
    text,
    imgSrc,
    imgSrcMedium,
    imgSrcSmall,
    imgAlt,
    imgWidth,
    imgCaption,
    youtubeVideoId,
    autoplay,
    buttons,
    className
}: HeroBannerProps) {
    const classes = clsx(
        'hero-banner',
        className
    )

    return (
        <section className={classes}>
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-lg-8 start-lg-5 hero-banner__heading">
                        {title && (
                            <h1 className="hero-banner__title" dangerouslySetInnerHTML={{ __html: title }} suppressHydrationWarning></h1>
                        )}
                        {intro && (
                            <p className="hero-banner__intro">{intro}</p>
                        )}
                    </div>
                    {(imgSrc || youtubeVideoId) && (
                        <figure className="c-lg-12 hero-banner__figure">
                            {youtubeVideoId && (
                                <VideoPlayer youtubeVideoId={youtubeVideoId} autoplay={autoplay}/>
                            )}
                            {(imgSrc && !youtubeVideoId) && (
                                <Image
                                    src={imgSrc}
                                    width={imgWidth}
                                    srcMedium={imgSrcMedium}
                                    srcSmall={imgSrcSmall}
                                    alt={imgAlt}
                                    sizes="(max-width: 2560px) 100vw"
                                />
                            )}
                            {(imgCaption && !youtubeVideoId) && (
                                <figcaption>{imgCaption}</figcaption>
                            )}
                        </figure>
                    )}
                    {text && (
                        <RichText content={text} className="hero-banner__text c-xl-6 start-xl-5 c-lg-7 start-lg-5" />
                    )}
                    {buttons && (
                        <div className="hero-banner__buttons c-xl-6 start-xl-5 c-lg-7 start-lg-5">
                            {buttons.map((button, index) => (
                                <React.Fragment key={index}>
                                    {button}
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        </section>
    );
}
