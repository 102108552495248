import React, { useState, useRef, useEffect } from 'react';
import clsx from 'clsx';

// components
import Section from '../Section';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import 'swiper/css';
import LabelText from '../LabelText';

// prop types
interface TrustBarProps {
    labelText?: string
    bulletColor?: 'black' | 'accent-1' | 'accent-2' | 'accent-3' | 'accent-4' | 'accent-5' | 'accent-6';
    slides?: React.ReactNode[]
    slidesPerView?: number
    speed?: number
    className?: string;
}

export default function TrustBar({
    labelText,
    bulletColor = 'accent-1',
    slides = [],
    slidesPerView = 6,
    speed = 8000,
    className
}: TrustBarProps) {
    const classes = clsx(
        'trust-bar',
        className,
    );

    return (
        <Section className={classes}>
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-lg-12">
                        <LabelText text={labelText} bulletColor={bulletColor} className="trust-bar__label" />
                        <Swiper
                            draggable={true}
                            centeredSlides={true}
                            autoplay={{
                                delay: 0,
                                disableOnInteraction: false,
                            }}
                            loop={true}
                            speed={speed}
                            modules={[Autoplay]}
                            breakpoints={
                                {
                                    0: {
                                        slidesPerView: 2,
                                        spaceBetween: 24
                                    },
                                    480: {
                                        slidesPerView: 4,
                                    },
                                    992: {
                                        spaceBetween:56,
                                        slidesPerView: slidesPerView,
                                    },
                                }
                            }
                        >
                            {slides.map((slide, index) => (
                                <SwiperSlide key={index}>{slide}</SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </div>
        </Section>
    );
}
