//libraries
import React from 'react'
import clsx from 'clsx'

// components
import Section from '/react4xp/components/Section'
import RichText from '/react4xp/components/RichText'

// prop types
export interface RichTextSectionProps {
    // section props
    section?: {
        dividerTop?: boolean
        dividerBottom?: boolean
    }

    hasInternalGrid?: boolean
    content?: string
    className?: string
}

export default function RichTextSection({
    section,
    hasInternalGrid = true,
    content,
    className
}: RichTextSectionProps) {
    return (
        <Section
            className={clsx('rich-text-section',`${hasInternalGrid ? 'rich-text-section--internal-grid' : ''}`, className)}
            dividerBottom={section?.dividerBottom}
            dividerTop={section?.dividerTop}
        >
            <div className='grid-container'>
                <div className='grid-row'>
                    <div className={`${hasInternalGrid ? 'c-xl-6 start-xl-5 c-lg-8 start-lg-4' : 'c-lg-12'}`}>
                        <RichText content={content} />
                    </div>
                </div>
            </div>
        </Section>
    )
}