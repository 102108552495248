import React from 'react';
import clsx from 'clsx';
import Section from '../Section';

// prop types
interface AuthorsProps {
    title?: string;
    authorCards?: React.ReactNode[];

    // section props
    section?: {
        dividerTop?: boolean
        dividerBottom?: boolean
        articleGrid?: boolean
    }
    className?: string;
}

export default function Authors({
    title,
    authorCards,
    section = {articleGrid: true},
    className
}: AuthorsProps) {
    const classes = clsx(
        'authors',
        className
    )
    return (
        <Section
            className={classes}
            dividerBottom={section?.dividerBottom}
            dividerTop={section?.dividerTop}
            articleGrid={section?.articleGrid}
        >
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-xl-6 start-xl-5 c-lg-8 start-lg-4">
                        {title && (
                            <header className="authors__header">
                                <h2 className="authors__title">{title}</h2>
                            </header>
                        )}
                        {authorCards && authorCards.length > 0 && (
                            <ul className='authors__list'>
                                {authorCards.map((card, index) => (
                                    <li key={index}>
                                        {card}
                                    </li>
                                ))}
                            </ul>
                        )}

                    </div>
                </div>
            </div>
        </Section>
    );
}
