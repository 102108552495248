import React from 'react';
import clsx from 'clsx';

// prop types
interface AuthorCardProps {
    name?: string;
    role?: string;
    phone?: string;
    email?: string;
    imgSrc?: string;
    imgAlt?: string;
    className?: string;
}

export default function AuthorCard({
    name,
    role,
    phone,
    email,
    imgSrc,
    imgAlt,
    className
}: AuthorCardProps) {
    const classes = clsx(
        'author-card',
        className
    )
    return (
        <div className={classes}>
            {imgSrc ? (
                <figure className="author-card__figure">
                    <img src={imgSrc} alt={imgAlt} />
                </figure>
            ): (
                <div className="author-card__figure"></div>
            )}
            <div className="author-card__content">
                {name || role ? (
                    <div className="author-card__personal-info">
                        {name && <h3 className="author-card__name">{name}</h3>}
                        {role && <p className="author-card__role">{role}</p>}
                    </div>
                ) : null}
                {phone || email ? (
                    <div className="author-card__contact-info">
                        {phone && <p className="author-card__phone">{phone}</p>}
                        {email && <p className="author-card__email">{email}</p>}
                    </div>
                ) : null}

            </div>
        </div>
    );
}
