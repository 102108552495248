// libraries
import React from 'react'

// prop types
interface ImageProps {
    src: string
    srcMedium?: string
    srcSmall?: string
    alt: string
    width?: number
    sizes?: string,
    lazyLoad?: boolean
    className?: string
}

export default function Image({
    src,
    srcMedium,
    srcSmall,
    alt,
    width = 800,
    sizes,
    lazyLoad = true,
    className,
}: ImageProps) {
    const defaultSizes = sizes || `
        (max-width: 480px) 100vw,
        (max-width: 768px) 50vw,
        ${width}px
    `

    const srcSet = [
        srcSmall && `${srcSmall} ${(width / 3.5).toFixed(0)}w`,
        srcMedium && `${srcMedium} ${(width / 1.75).toFixed(0)}w`,
        `${src} ${width}w`
    ].filter(Boolean).join(', ')

    return (
        <img
            alt={alt}
            title={alt}
            width={width}
            srcSet={srcSet}
            sizes={defaultSizes}
            src={src}
            loading={lazyLoad ? 'lazy' : 'eager'}
            className={className}
        />
    )
}