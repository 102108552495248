import React from 'react';
import clsx from 'clsx';

// prop types
interface StarRatingProps {
    rating: number;
    totalStars: number;
    colorMode?: 'light' | 'dark';
    className?: string;
}

export default function StarRating({
    rating,
    totalStars,
    colorMode = 'dark',
    className,
}: StarRatingProps) {
    const stars = Array.from({ length: totalStars }, (_, i) => i < rating ? (
        <svg
            key={i}
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="star-rating__star"
        >
            <path
                d="M8 0.000488281L9.79611 5.52835H15.6085L10.9062 8.94476L12.7023 14.4726L8 11.0562L3.29772 14.4726L5.09383 8.94476L0.391548 5.52835H6.20389L8 0.000488281Z"
                fill="black"
            />
        </svg>
    ) : (
        <svg
            key={i}
            width="16"
            height="15"
            viewBox="0 0 16 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            className="star-rating__star star-rating__star--empty"
        >
            <path
                d="M8 1.61852L9.32058 5.68286L9.43284 6.02835H9.79611H14.0696L10.6123 8.54025L10.3184 8.75378L10.4306 9.09927L11.7512 13.1636L8.29389 10.6517L8 10.4382L7.70611 10.6517L4.24877 13.1636L5.56936 9.09927L5.68162 8.75378L5.38772 8.54025L1.93039 6.02835H6.20389H6.56716L6.67942 5.68286L8 1.61852Z"
                stroke="black"
            />
        </svg>
    ));

    const classes = clsx(
        'star-rating', className,
        `star-rating--${colorMode}`
    );

    return (
        <div className={classes} aria-label={`Rating: ${rating} / ${totalStars}`} role="img">
            <div className="star-rating__stars" aria-hidden="true">
                {stars}
            </div>
            <p className="star-rating__text">{`${rating}/${totalStars}`}</p>
        </div>
    );
}
