import React from 'react';
import clsx from 'clsx';

// components
import Image from '../Image';
// icons


// prop types
interface ArticleHeroProps {
    title?: string;
    intro?: string;
    imgSrc?: string;
    imgSrcMedium?: string;
    imgSrcSmall?: string;
    imgAlt?: string;
    imgWidth?: number;
    imgCaption?: string;
    buttons?: React.ReactNode[];
    authors?: string;
    className?: string;
}

export default function ArticleHero({
    title,
    intro,
    imgSrc,
    imgSrcMedium,
    imgSrcSmall,
    imgAlt,
    imgWidth,
    imgCaption,
    buttons,
    authors,
    className
}: ArticleHeroProps) {
    const classes = clsx(
        'article-hero',
        className
    )
    return (
        <section className={classes}>
            <div className="grid-container">
                <div className="grid-row">
                    <div className="c-lg-8 start-lg-5 article-hero__content">
                        {title && (
                            <h1 className="article-hero__title">{title}</h1>
                        )}
                        {intro && (
                            <p className="article-hero__intro">{intro}</p>
                        )}
                        {buttons && (
                            <div className="article-hero__buttons">
                                {buttons.map((button, index) => (
                                    <React.Fragment key={index}>
                                        {button}
                                    </React.Fragment>
                                ))}
                            </div>
                        )}
                        {authors && (
                            <p className="article-hero__authors">{authors}</p>
                        )}
                    </div>
                    {imgSrc && (
                        <figure className="c-lg-12 article-hero__figure">
                            <Image
                                src={imgSrc}
                                width={imgWidth}
                                srcMedium={imgSrcMedium}
                                srcSmall={imgSrcSmall}
                                alt={imgAlt}
                                sizes="(max-width: 2560px) 100vw"
                            />
                            {imgCaption && <figcaption>{imgCaption}</figcaption>}
                        </figure>
                    )}
                </div>
            </div>
        </section>
    );
}
