import React, { useState } from 'react';
import clsx from 'clsx';
import Section from '../Section';

interface Filters {
    label: string;
    value: string;
}

interface TilesProps {
    sectionHeader?: React.ReactNode;
    cards?: React.ReactElement<{ type: string }>[];
    filters?: Filters[];
    selectedLocation?: string;
    maxCards?: number;
    layout?: '1-column' | '3-columns' | '4-columns'; // This prop overwrites the "columns" value. It sets the grid layout independent of the "maxCards" value.
    className?: string;
}

export default function Tiles({
    sectionHeader,
    cards,
    filters,
    selectedLocation,
    maxCards,
    layout, // In case no value is provided, the layout is determined by the "maxCards" value
    className
}: TilesProps) {
    const [selectedFilter, setSelectedFilter] = useState<string | null>(selectedLocation || null);

    const filteredCards = selectedFilter ? cards.filter(card => card.props.type === selectedFilter) : cards

    let columns = 0;

    if(maxCards) {
        columns = (filteredCards && filteredCards.length > 3 && maxCards > 3) ? 4 : 3;
    }else{
        columns = (filteredCards && filteredCards.length > 3) ? 4 : 3;
    }

    const classes = clsx('tiles', className);

    const handleFilterChange = (filter: string) => {
        setSelectedFilter((prev) => (prev === filter ? null : filter));

        // blur filter input when changing filter
        const filterInput = document.querySelector(`input[name="${filter}"]`);
        if (filterInput) {
            (filterInput as HTMLElement).blur();
        }
    };

    return (
        <Section className={classes}>
            {sectionHeader}
            {filters && filters.length > 0 && (
                <div className="grid-container tiles__filters">
                    <div className="grid-row">
                        <ul className="c-lg-12 tiles__filters-list">
                            {filters.map((filter, index) => {
                                const id = String(index)
                                const isSelected = selectedFilter === filter.value;
                                const buttonClasses = clsx(
                                    'btn btn--md',
                                    isSelected ? 'btn--primary' : 'btn--secondary'
                                );

                                return (
                                    <li key={index} className="tiles__filter-item">
                                        <label htmlFor={id} className={buttonClasses}>
                                            <input
                                                type="checkbox"
                                                name={filter.value}
                                                id={id}
                                                checked={isSelected}
                                                onChange={() => handleFilterChange(filter.value)}
                                            />
                                            {filter.label}
                                        </label>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </div>
            )}
            <div className="grid-container">
                <div className="grid-row tiles__wrapper">
                    {returnCardsUsingMaxCardsParameter(cards, maxCards, selectedFilter, columns, layout)}
                </div>
            </div>
        </Section>
    );
}

function returnCardsUsingMaxCardsParameter(cards, maxCards, selectedFilter, columns, layout) {
    let count = 0
    return cards?.map((card, index) => {
        if ((!selectedFilter || card.props.type === selectedFilter) && (!maxCards || count < maxCards)) {
            count++
            const key = `index-${index}-filter-${selectedFilter}`

            if (layout === '4-columns') {
                return (
                    <div className={clsx('tiles__item c-lg-3 c-md-6 c-xs-6')} key={key}>
                        {card}
                    </div>
                )
            }

            if (layout === '3-columns') {
                return (
                    <div className={clsx('tiles__item c-lg-4 c-md-4 c-xs-12')} key={key}>
                        {card}
                    </div>
                );
            }

            if (layout === '1-column') {
                return (
                    <div className={clsx('tiles__item c-lg-12')} key={key}>
                        {card}
                    </div>
                );
            }

            // Default layout based on column calculation
            return (
                <div className={clsx(`tiles__item c-lg-${columns === 3 ? 4 : 3} c-md-${columns === 3 ? 4 : 6} c-xs-${columns === 4 ? 6 : 12}`)} key={key}>
                    {card}
                </div>
            )
        }
    })
}
